<template>
    <Form
      ref="form"
      :model="formData"
      :label-width="80"
      :label-colon="true"
      :rules="ruleValidate"
    >
      <FormItem label="序号" prop="seq">
        <InputNumber :max='999999999' :min="1" v-model="formData.seq" placeholder="请输入数字"></InputNumber>
      </FormItem>
      <FormItem label="标题" prop="title">
        <Input v-model.trim="formData.title" placeholder="请输入标题" :maxlength="30"></Input>
      </FormItem>
      <FormItem label="详情">
        <Input v-model.trim="formData.subtitle" placeholder="请输入详情描述" :maxlength="50"></Input>
      </FormItem>
      <FormItem label="封面">
        <template v-if="image[0]">
          <img :src="image[0].url" width="150" />
        </template>
        <Upload
          :before-upload="beforeImage"
          :show-upload-list="true"
          :action="actionImage"
          :data="uploadImageData"
          :on-success="imageSuccess"
          :default-file-list="image"
          :format="['jpg', 'jpeg', 'png']"
          :on-format-error="imageError"
        >
          <Button icon="ios-cloud-upload-outline">上传图片</Button>
        </Upload>
      </FormItem>
      <FormItem label="视频">
        <div
          class="video-upload-list"
          v-for="(item, index) in video"
          :key="index"
        >
          <template v-if="item">
            <video
              :src="item.url"
              controls="controls"
              width="300"
              height="150"
            ></video>
          </template>
        </div>

        <Upload
          ref="upload"
          :before-upload="beforeVideo"
          :action="actionVideo"
          :show-upload-list="false"
          :data="uploadVideoData"
          :on-success="videoSuccess"
          :default-file-list="video"
          :format="['mp4']"
          :on-format-error="videoError"
        >
          <Button icon="ios-cloud-upload-outline">上传视频</Button>
        </Upload>
      </FormItem>
    </Form>
</template>

<script>
export default {
  data() {
    return {
      actionImage: "",
      actionVideo: "",
      formData: {
        title: "",
        subtitle: "",
        seq: 1,
      },
      ruleValidate: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        seq: [{ required: true, type: "number",message: "请输入序号", trigger: "blur"}],
      },
      // 图片、视频上传参数
      uploadImageData: {},
      uploadVideoData: {},

      //图片、视频上传显示
      image: [],
      video: [],
      //图片、视频名称
      imageName: "",
      videoName: "",
      //图片、视频路径
      imagePath: "",
      videoPath: "",
    };
  },
  methods: {
    //图片格式错误
    imageError(file) {
        this.$Modal.info({
        title: '文件格式不正确',
        content: '文件 ' + file.name + ' 格式不正确，请上传 jpg、jpeg或 png 格式的图片。'
    });
    this.spinImageShow = false;
    },
    videoError(file) {
      this.$Modal.info({
        title: '文件格式不正确',
        content: '文件 ' + file.name + ' 格式不正确，请上传 mp4 格式的视频。'
    });
    this.spinVideoShow = false;
    },
    //视频上传成功
    videoSuccess(res, file) {
      this.video.splice(0, 1, {
        name: file.name,
        url: this.videoPath,
      });
      this.spinVideoShow = false;
    },
    //图片上传成功
    imageSuccess(res, file) {
      this.image.splice(0, 1, {
        name: file.name,
        url: this.imagePath,
      });
      this.spinImageShow = false;
    },
    //图片上传之前
    beforeImage(file) {
      return this.$get(
        "/datamsg/api/common/file/getWebPolicy?bucketName=liefeng"
      ).then((res) => {
        if (res.code == 200) {
          this.spinImageShow = true;
          let now = Date.parse(new Date()) / 1000;
          this.actionImage = "https://liefeng.oss-cn-shenzhen.aliyuncs.com";
          this.uploadImageData = {
            name: now + file.name,
            host: res.data.host,
            key: res.data.key + now + file.name,
            policy: res.data.encodedPolicy,
            OSSAccessKeyId: res.data.accessId,
            success_action_status: "200",
            signature: res.data.postSignature,
          };
          this.imageName = now + file.name;
          this.imagePath = res.data.host + res.data.key + this.imageName;
        }else {
          this.$Message.error({
            background: true,
            content: "图片上传失败！"
          })
          this.spinImageShow = false;
        }
      }).catch(err => {
        console.log(err);
      })
    },
    //视频上传之前
    beforeVideo(file) {
      return this.$get(
        "/datamsg/api/common/file/getWebPolicy?bucketName=liefeng"
      ).then((res) => {
        if (res.code == 200) {
          this.spinVideoShow = true;
          let now = Date.parse(new Date()) / 1000;
          this.actionVideo = "https://liefeng.oss-cn-shenzhen.aliyuncs.com";
          this.uploadVideoData = {
            name: now + file.name,
            host: res.data.host,
            key: res.data.key + now + file.name,
            policy: res.data.encodedPolicy,
            OSSAccessKeyId: res.data.accessId,
            success_action_status: "200",
            signature: res.data.postSignature,
          };
          this.videoName = now + file.name;
          this.videoPath = res.data.host + res.data.key + this.videoName;
        }else {
          this.$Message.error({
            background: true,
            content: "视频上传失败！"
          })
          this.spinVideoShow = false;
        }
      }).catch(err => {
        console.log(err);
      })
    },
    //保存
    save() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$post("/voteapi/api/pc/componentVote/addVoteItem", {
            businessType: "2",
            businessCode: this.$route.query.id,
            title: this.formData.title,
            subtitle: this.formData.subtitle,
            itemNum: this.formData.seq,
            coverImg: this.imagePath,
            converVideo: this.videoPath,
            seq: this.formData.seq,
            modifier: parent.vue.loginInfo.userinfo.nickName,
          }).then((res) => {
            if (res.code == 200) {
               this.$Message.success({
                  background: true,
                  content: "新增成功",
              });
                this.$emit("modalStatus", false);
                this.$emit('reloadTable');
            }else {
              this.$Message.error({
                  background: true,
                  content: res.desc,
              });
            }
          }).catch(err => {
            console.log(err);
          })
        }else {
          this.$emit("changeModal1", false);
          this.$Message.error({
             background: true,
              content: '添加失败'
          })
        }
      });
    },
    //取消
    cancel() {
      this.$emit("changeModal1", false);
    },
  },
};
</script>
    
<style scoped lang='less'>
.ivu-input-number {
  width: 50%;
}

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
.ivu-spin {
  margin-left: 10px;
  text-align: left;
  color: #57a3f3;
  font-size: 16px;
}
</style>